"use strict";

// src/types.ts
var Pitches = /* @__PURE__ */ ((Pitches2) => {
  Pitches2[Pitches2["BALL"] = 0] = "BALL";
  Pitches2[Pitches2["BALL_WILD"] = 1] = "BALL_WILD";
  Pitches2[Pitches2["STRIKE_SWINGING"] = 2] = "STRIKE_SWINGING";
  Pitches2[Pitches2["STRIKE_LOOKING"] = 3] = "STRIKE_LOOKING";
  Pitches2[Pitches2["STRIKE_FOUL"] = 4] = "STRIKE_FOUL";
  Pitches2[Pitches2["STRIKE_FOUL_ZONE"] = 5] = "STRIKE_FOUL_ZONE";
  Pitches2[Pitches2["STRIKE_FOUL_CAUGHT"] = 6] = "STRIKE_FOUL_CAUGHT";
  Pitches2[Pitches2["INPLAY_INFIELD_GRD_OUT"] = 7] = "INPLAY_INFIELD_GRD_OUT";
  Pitches2[Pitches2["INPLAY_INFIELD_AIR_OUT"] = 8] = "INPLAY_INFIELD_AIR_OUT";
  Pitches2[Pitches2["INPLAY_INFIELD_AIR_OUT_INFIELD_FLY"] = 9] = "INPLAY_INFIELD_AIR_OUT_INFIELD_FLY";
  Pitches2[Pitches2["INPLAY_INFIELD_OUT_DP_SUCCESS"] = 10] = "INPLAY_INFIELD_OUT_DP_SUCCESS";
  Pitches2[Pitches2["INPLAY_INFIELD_OUT_DP_FAIL"] = 11] = "INPLAY_INFIELD_OUT_DP_FAIL";
  Pitches2[Pitches2["INPLAY_OUTFIELD_OUT"] = 12] = "INPLAY_OUTFIELD_OUT";
  Pitches2[Pitches2["INPLAY_OUTFIELD_OUT_TAG_SUCCESS"] = 13] = "INPLAY_OUTFIELD_OUT_TAG_SUCCESS";
  Pitches2[Pitches2["INPLAY_OUTFIELD_OUT_TAG_FAIL"] = 14] = "INPLAY_OUTFIELD_OUT_TAG_FAIL";
  Pitches2[Pitches2["INPLAY_INFIELD_SINGLE"] = 15] = "INPLAY_INFIELD_SINGLE";
  Pitches2[Pitches2["INPLAY_OUTFIELD_SINGLE"] = 16] = "INPLAY_OUTFIELD_SINGLE";
  Pitches2[Pitches2["INPLAY_DOUBLE"] = 17] = "INPLAY_DOUBLE";
  Pitches2[Pitches2["INPLAY_TRIPLE"] = 18] = "INPLAY_TRIPLE";
  Pitches2[Pitches2["INPLAY_HOMERUN"] = 19] = "INPLAY_HOMERUN";
  Pitches2[Pitches2["INTERFERENCE"] = 20] = "INTERFERENCE";
  return Pitches2;
})(Pitches || {});
var GameEvent = /* @__PURE__ */ ((GameEvent2) => {
  GameEvent2["START"] = "START";
  GameEvent2["END"] = "END";
  GameEvent2["PLATE_APPEARANCE"] = "PA";
  GameEvent2["RBI"] = "RBI";
  GameEvent2["INNING_END"] = "INNING";
  GameEvent2["WALK"] = "W";
  GameEvent2["WALK_OFF"] = "WO";
  GameEvent2["STRIKEOUT_SWINGING"] = "K";
  GameEvent2["STRIKEOUT_LOOKING"] = "_K";
  GameEvent2["RUNS_SCORED"] = "R";
  GameEvent2["LEAD_CHANGE"] = "LC";
  GameEvent2["LEAD_LOST"] = "LL";
  return GameEvent2;
})(GameEvent || {});
var InningHalf = /* @__PURE__ */ ((InningHalf2) => {
  InningHalf2[InningHalf2["TOP"] = 0] = "TOP";
  InningHalf2[InningHalf2["BOTTOM"] = 1] = "BOTTOM";
  return InningHalf2;
})(InningHalf || {});
var Bases = /* @__PURE__ */ ((Bases2) => {
  Bases2[Bases2["FIRST"] = 0] = "FIRST";
  Bases2[Bases2["SECOND"] = 1] = "SECOND";
  Bases2[Bases2["THIRD"] = 2] = "THIRD";
  Bases2[Bases2["HOME"] = 3] = "HOME";
  return Bases2;
})(Bases || {});
var OptionalRules = /* @__PURE__ */ ((OptionalRules2) => {
  OptionalRules2[OptionalRules2["RunnersAdvanceOnWildPitch"] = 0] = "RunnersAdvanceOnWildPitch";
  OptionalRules2[OptionalRules2["RunnersAdvanceExtraOn2Outs"] = 1] = "RunnersAdvanceExtraOn2Outs";
  OptionalRules2[OptionalRules2["CaughtLookingRule"] = 2] = "CaughtLookingRule";
  OptionalRules2[OptionalRules2["FoulToTheZoneIsStrikeOut"] = 3] = "FoulToTheZoneIsStrikeOut";
  OptionalRules2[OptionalRules2["ThirdBaseCanTag"] = 4] = "ThirdBaseCanTag";
  OptionalRules2[OptionalRules2["AllowSinglePlayRunsToPassLimit"] = 5] = "AllowSinglePlayRunsToPassLimit";
  OptionalRules2[OptionalRules2["InFieldFly"] = 6] = "InFieldFly";
  OptionalRules2[OptionalRules2["DoubleRunLimitInLastInning"] = 7] = "DoubleRunLimitInLastInning";
  return OptionalRules2;
})(OptionalRules || {});
var Position = /* @__PURE__ */ ((Position2) => {
  Position2[Position2["Pitcher"] = 0] = "Pitcher";
  Position2[Position2["Infield"] = 1] = "Infield";
  Position2[Position2["Outfield"] = 2] = "Outfield";
  Position2[Position2["Bench"] = 3] = "Bench";
  return Position2;
})(Position || {});

// src/factory.ts
import { mergeDeepRight } from "ramda";
var pid = 0;
var tid = 0;
var defaultTeam = (id = `${tid}`, name = `team-${id}`, playerNames) => {
  tid += 1;
  const names = playerNames || [
    `${name && `${name} - `}playerA`,
    `${name && `${name} - `}playerB`,
    `${name && `${name} - `}playerC`,
    `${name && `${name} - `}playerD`
  ];
  return names.reduce((acc, player, index) => {
    const newPlayer = defaultPlayer(player);
    acc.roster[newPlayer.id] = newPlayer;
    acc.lineup.push(newPlayer.id);
    acc.startingLineup.push(newPlayer.id);
    acc.defense[newPlayer.id] = index;
    acc.startingDefense[newPlayer.id] = index;
    return acc;
  }, {
    roster: {},
    lineup: [],
    defense: {},
    startingLineup: [],
    startingDefense: {},
    id: `${id}`,
    name
  });
};
var newTeam = (players, teamId, teamName) => {
  if (players.length === 0)
    return void 0;
  const id = teamId || tid;
  tid += 1;
  return players.reduce((acc, player) => {
    const newPlayer = defaultPlayer(player.name, player.id);
    acc.roster[player.id] = newPlayer;
    acc.lineup.push(newPlayer.id);
    acc.startingLineup.push(newPlayer.id);
    acc.defense[player.id] = player.position;
    acc.startingDefense[player.id] = player.position;
    return acc;
  }, {
    roster: {},
    lineup: [],
    defense: {},
    startingLineup: [],
    startingDefense: {},
    id: `${id}`,
    name: teamName || `team-${id}`
  });
};
var defaultGame = (awayTeam = defaultTeam("away"), homeTeam = defaultTeam("home")) => {
  pid = 0;
  const firstBatter = awayTeam.lineup[0] ?? "mockBatter";
  const homesFirstBatter = homeTeam.lineup[0] ?? "mockBatterHome";
  return {
    boxScore: [{
      homeTeam: 0,
      awayTeam: 0
    }],
    inning: {
      number: 1,
      half: 0 /* TOP */
    },
    outs: 0,
    atBat: firstBatter,
    nextHalfAtBat: homesFirstBatter,
    count: {
      balls: 0,
      strikes: 0
    },
    bases: {
      [0 /* FIRST */]: 0,
      [1 /* SECOND */]: 0,
      [2 /* THIRD */]: 0,
      [3 /* HOME */]: 0
    },
    awayTeam,
    homeTeam,
    gameOver: false,
    gameStarted: false,
    configuration: defaultConfiguration(),
    pitches: [],
    manualEdits: []
  };
};
var defaultPlayer = (name = "mockPlayer", id = `${pid}`) => {
  pid += 1;
  return {
    id,
    name,
    offenseStats: {
      plateAppearance: 0,
      atbats: 0,
      hits: 0,
      strikeoutsSwinging: 0,
      strikeoutsLooking: 0,
      walks: 0,
      singles: 0,
      doubles: 0,
      triples: 0,
      homeruns: 0,
      grandslams: 0,
      RBI: 0,
      LOB: 0,
      runs: 0,
      groundOuts: 0,
      flyOuts: 0,
      doublePlays: 0,
      doublePlayFails: 0,
      sacrificeFly: 0,
      walkoffs: 0
    },
    defenseStats: {
      pitching: {
        battersFaced: 0,
        balls: 0,
        strikes: 0,
        wildPitches: 0,
        walks: 0,
        strikeoutsSwinging: 0,
        strikeoutsLooking: 0,
        groundOuts: 0,
        doublePlays: 0,
        flyOuts: 0,
        singles: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsAllowed: 0
      },
      fielding: {
        putouts: 0,
        infieldErrors: 0,
        inningsPlayed: 0,
        DPA: 0,
        DPSuccess: 0,
        tagThrowAttempts: 0,
        tagThrowSuccess: 0
      }
    }
  };
};
var defaultConfiguration = () => {
  return {
    maxStrikes: 3,
    maxBalls: 4,
    maxOuts: 3,
    maxInnings: 5,
    maxRuns: 5,
    maxFielders: 2,
    rules: defaultRules(),
    recordingStats: true
  };
};
var defaultRules = () => {
  return {
    [0 /* RunnersAdvanceOnWildPitch */]: true,
    [1 /* RunnersAdvanceExtraOn2Outs */]: true,
    [2 /* CaughtLookingRule */]: true,
    [3 /* FoulToTheZoneIsStrikeOut */]: true,
    [4 /* ThirdBaseCanTag */]: true,
    [5 /* AllowSinglePlayRunsToPassLimit */]: false,
    [6 /* InFieldFly */]: true,
    [7 /* DoubleRunLimitInLastInning */]: true
  };
};
var NEW_COUNT = {
  balls: 0,
  strikes: 0
};
var EMPTY_BASES = {
  [0 /* FIRST */]: 0,
  [1 /* SECOND */]: 0,
  [2 /* THIRD */]: 0,
  [3 /* HOME */]: 0
};
var EMPTY_BOX = {
  awayTeam: 0,
  homeTeam: 0
};

// src/gameReducer.ts
import mergeDeepRight3 from "ramda/src/mergeDeepRight.js";

// src/bases/leadRunner.ts
var forcedRunner = (bases) => {
  if (bases[2 /* THIRD */] > 0 && bases[1 /* SECOND */] > 0 && bases[0 /* FIRST */] > 0)
    return 2 /* THIRD */;
  if (bases[1 /* SECOND */] > 0 && bases[0 /* FIRST */] > 0)
    return 1 /* SECOND */;
  if (bases[0 /* FIRST */] > 0)
    return 0 /* FIRST */;
  return void 0;
};

// src/bases/advanceRunners.ts
var advanceRunners = (bases, basesToAdvance, advanceUnforced = true) => {
  const leadForced = forcedRunner(bases);
  switch (basesToAdvance) {
    case 1: {
      return {
        [0 /* FIRST */]: 0,
        [1 /* SECOND */]: advanceUnforced || leadForced === 0 /* FIRST */ ? bases[0 /* FIRST */] : bases[1 /* SECOND */],
        [2 /* THIRD */]: advanceUnforced || leadForced === 1 /* SECOND */ ? bases[1 /* SECOND */] : bases[2 /* THIRD */],
        [3 /* HOME */]: advanceUnforced || leadForced === 2 /* THIRD */ ? bases[3 /* HOME */] + bases[2 /* THIRD */] : bases[3 /* HOME */]
      };
    }
    case 2: {
      return {
        [3 /* HOME */]: bases[3 /* HOME */] + bases[2 /* THIRD */] + bases[1 /* SECOND */],
        [2 /* THIRD */]: bases[0 /* FIRST */],
        [1 /* SECOND */]: 0,
        [0 /* FIRST */]: 0
      };
    }
    case 3: {
      return {
        [3 /* HOME */]: bases[3 /* HOME */] + bases[2 /* THIRD */] + bases[1 /* SECOND */] + bases[0 /* FIRST */],
        [2 /* THIRD */]: 0,
        [1 /* SECOND */]: 0,
        [0 /* FIRST */]: 0
      };
    }
    case 4: {
      return {
        [3 /* HOME */]: bases[3 /* HOME */] + bases[2 /* THIRD */] + bases[1 /* SECOND */] + bases[0 /* FIRST */] + 1,
        [2 /* THIRD */]: 0,
        [1 /* SECOND */]: 0,
        [0 /* FIRST */]: 0
      };
    }
    default:
      return { ...bases };
  }
};
var advanceRunners_default = advanceRunners;

// src/batters/batterUp.ts
import mergeDeepRight2 from "ramda/src/mergeDeepRight.js";

// src/teams/getTeams.ts
var getOffense = (game) => {
  return game.inning.half === 0 /* TOP */ ? game.awayTeam : game.homeTeam;
};
var getOffenseKey = (game) => {
  return game.inning.half === 0 /* TOP */ ? "awayTeam" : "homeTeam";
};
var getDefense = (game) => {
  return game.inning.half === 0 /* TOP */ ? game.homeTeam : game.awayTeam;
};
var getDefenseKey = (game) => {
  return game.inning.half === 0 /* TOP */ ? "homeTeam" : "awayTeam";
};

// src/history/lastPitch.ts
import findLast from "ramda/src/findLast.js";
var lastPitch = (state) => {
  return findLast((candidate) => {
    return Object.values(Pitches).includes(candidate);
  }, state.pitches) ?? -1;
};
var lastPitch_default = lastPitch;

// src/defense/getPosition.ts
var getPosition = (team, pos) => {
  return Object.entries(team.defense).reduce((acc, [id, position]) => {
    if (position === pos) {
      acc.push(id);
    }
    return acc;
  }, []);
};
var getPitcher = (team) => {
  const pitcher = getPosition(team, 0 /* Pitcher */)[0];
  if (!pitcher) {
    console.warn("no pitcher found");
  }
  return pitcher;
};
var getInfield = (team) => getPosition(team, 1 /* Infield */);
var getOutfield = (team) => getPosition(team, 2 /* Outfield */);
var getBench = (team) => getPosition(team, 3 /* Bench */);

// src/bases/runnersOn.ts
var runnersOn = (state) => {
  const { [0 /* FIRST */]: first, [1 /* SECOND */]: second, [2 /* THIRD */]: third } = state.bases;
  return first + second + third;
};

// src/stats/statBuilder.ts
var record = (player) => {
  let internalPlayer = { ...player };
  const builder = {
    player: internalPlayer,
    offense: (stat, amt) => {
      internalPlayer = {
        ...internalPlayer,
        offenseStats: {
          ...internalPlayer.offenseStats,
          [stat]: Math.max(internalPlayer.offenseStats[stat] + amt, 0)
        }
      };
      return builder;
    },
    plateAppearance: () => {
      return builder.offense("plateAppearance", 1);
    },
    atBat: () => {
      return builder.offense("atbats", 1);
    },
    strikeoutSwinging: () => {
      return builder.offense("atbats", 1).offense("strikeoutsSwinging", 1);
    },
    strikeoutLooking: () => {
      return builder.offense("atbats", 1).offense("strikeoutsLooking", 1);
    },
    walk: () => {
      return builder.offense("walks", 1);
    },
    single: () => {
      return builder.offense("atbats", 1).offense("singles", 1).offense("hits", 1);
    },
    double: () => {
      return builder.offense("atbats", 1).offense("doubles", 1).offense("hits", 1);
    },
    triple: () => {
      return builder.offense("atbats", 1).offense("triples", 1).offense("hits", 1);
    },
    homerun: () => {
      return builder.offense("atbats", 1).offense("homeruns", 1).offense("hits", 1).offense("runs", 1);
    },
    pitching: (stat, amt) => {
      internalPlayer = {
        ...internalPlayer,
        defenseStats: {
          ...internalPlayer.defenseStats,
          pitching: {
            ...internalPlayer.defenseStats.pitching,
            [stat]: Math.max(internalPlayer.defenseStats.pitching[stat] + amt, 0)
          }
        }
      };
      return builder;
    },
    fielding: (stat, amt) => {
      internalPlayer = {
        ...internalPlayer,
        defenseStats: {
          ...internalPlayer.defenseStats,
          fielding: {
            ...internalPlayer.defenseStats.fielding,
            [stat]: Math.max(internalPlayer.defenseStats.fielding[stat] + amt, 0)
          }
        }
      };
      return builder;
    },
    done: () => internalPlayer
  };
  return builder;
};

// src/stats/statsReducer.ts
function offenseStats(team, game, pitch2) {
  if (!game.configuration.recordingStats)
    return team;
  const playerAtBat = team.roster[game.atBat];
  if (!playerAtBat)
    return team;
  const batter = playerAtBat.id;
  switch (pitch2) {
    case "PA" /* PLATE_APPEARANCE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).plateAppearance().done()
        }
      };
    case "W" /* WALK */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).walk().done()
        }
      };
    case "K" /* STRIKEOUT_SWINGING */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).strikeoutSwinging().done()
        }
      };
    case "_K" /* STRIKEOUT_LOOKING */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).strikeoutLooking().done()
        }
      };
    case "RBI" /* RBI */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).offense("RBI", game.bases[3 /* HOME */]).done()
        }
      };
    case "INNING" /* INNING_END */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).offense("LOB", runnersOn(game)).done()
        }
      };
    case 5 /* STRIKE_FOUL_ZONE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).strikeoutSwinging().done()
        }
      };
    case 7 /* INPLAY_INFIELD_GRD_OUT */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).atBat().offense("groundOuts", 1).done()
        }
      };
    case 10 /* INPLAY_INFIELD_OUT_DP_SUCCESS */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).atBat().offense("groundOuts", 1).offense("doublePlays", 1).done()
        }
      };
    case 11 /* INPLAY_INFIELD_OUT_DP_FAIL */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).atBat().offense("doublePlayFails", 1).done()
        }
      };
    case 6 /* STRIKE_FOUL_CAUGHT */:
    case 12 /* INPLAY_OUTFIELD_OUT */:
    case 14 /* INPLAY_OUTFIELD_OUT_TAG_FAIL */:
    case 8 /* INPLAY_INFIELD_AIR_OUT */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).atBat().offense("flyOuts", 1).done()
        }
      };
    case 13 /* INPLAY_OUTFIELD_OUT_TAG_SUCCESS */: {
      if (!game.configuration.rules[4 /* ThirdBaseCanTag */])
        return team;
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).atBat().offense("flyOuts", 1).offense("sacrificeFly", 1).done()
        }
      };
    }
    case 15 /* INPLAY_INFIELD_SINGLE */:
    case 16 /* INPLAY_OUTFIELD_SINGLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).single().done()
        }
      };
    case 17 /* INPLAY_DOUBLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).double().done()
        }
      };
    case 18 /* INPLAY_TRIPLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).triple().done()
        }
      };
    case 19 /* INPLAY_HOMERUN */: {
      const runs = game.bases[3 /* HOME */];
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).homerun().offense("grandslams", runs === 4 ? 1 : 0).done()
        }
      };
    }
    case "WO" /* WALK_OFF */: {
      return {
        ...team,
        roster: {
          ...team.roster,
          [batter]: record(team.roster[batter]).offense("walkoffs", 1).done()
        }
      };
    }
    default:
      return team;
  }
}
function pitchingStats(team, game, pitch2) {
  if (!game.configuration.recordingStats)
    return team;
  const pitcher = getPitcher(team);
  if (!pitcher)
    return team;
  switch (pitch2) {
    case "PA" /* PLATE_APPEARANCE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("battersFaced", 1).done()
        }
      };
    case "W" /* WALK */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("walks", 1).done()
        }
      };
    case "K" /* STRIKEOUT_SWINGING */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("strikeoutsSwinging", 1).done()
        }
      };
    case "_K" /* STRIKEOUT_LOOKING */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("strikeoutsLooking", 1).done()
        }
      };
    case "RBI" /* RBI */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("runsAllowed", game.bases[3 /* HOME */]).done()
        }
      };
    case 0 /* BALL */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("balls", 1).done()
        }
      };
    case 1 /* BALL_WILD */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("balls", 1).pitching("wildPitches", 1).done()
        }
      };
    case 4 /* STRIKE_FOUL */:
    case 2 /* STRIKE_SWINGING */: {
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("strikes", 1).done()
        }
      };
    }
    case 5 /* STRIKE_FOUL_ZONE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("strikes", 1).pitching("strikeoutsSwinging", 1).done()
        }
      };
    case 3 /* STRIKE_LOOKING */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("strikes", 1).done()
        }
      };
    case 11 /* INPLAY_INFIELD_OUT_DP_FAIL */:
    case 7 /* INPLAY_INFIELD_GRD_OUT */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("groundOuts", 1).done()
        }
      };
    case 10 /* INPLAY_INFIELD_OUT_DP_SUCCESS */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("doublePlays", 1).pitching("groundOuts", 1).done()
        }
      };
    case 6 /* STRIKE_FOUL_CAUGHT */:
    case 8 /* INPLAY_INFIELD_AIR_OUT */:
    case 9 /* INPLAY_INFIELD_AIR_OUT_INFIELD_FLY */:
    case 12 /* INPLAY_OUTFIELD_OUT */:
    case 14 /* INPLAY_OUTFIELD_OUT_TAG_FAIL */:
    case 13 /* INPLAY_OUTFIELD_OUT_TAG_SUCCESS */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("flyOuts", 1).done()
        }
      };
    case 15 /* INPLAY_INFIELD_SINGLE */:
    case 16 /* INPLAY_OUTFIELD_SINGLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("singles", 1).done()
        }
      };
    case 17 /* INPLAY_DOUBLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("doubles", 1).done()
        }
      };
    case 18 /* INPLAY_TRIPLE */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("triples", 1).done()
        }
      };
    case 19 /* INPLAY_HOMERUN */:
      return {
        ...team,
        roster: {
          ...team.roster,
          [pitcher]: record(team.roster[pitcher]).pitching("homeruns", 1).done()
        }
      };
    default:
      return team;
  }
}
function fieldingStats(team, game, pitch2) {
  if (!game.configuration.recordingStats)
    return team;
  switch (pitch2) {
    case "PA" /* PLATE_APPEARANCE */:
    case "W" /* WALK */:
    case "RBI" /* RBI */:
    case "INNING" /* INNING_END */:
    case 0 /* BALL */:
    case 1 /* BALL_WILD */:
    case 2 /* STRIKE_SWINGING */:
    case 5 /* STRIKE_FOUL_ZONE */:
    case 3 /* STRIKE_LOOKING */:
    case 7 /* INPLAY_INFIELD_GRD_OUT */:
    case 10 /* INPLAY_INFIELD_OUT_DP_SUCCESS */:
    case 11 /* INPLAY_INFIELD_OUT_DP_FAIL */:
    case 6 /* STRIKE_FOUL_CAUGHT */:
    case 12 /* INPLAY_OUTFIELD_OUT */:
    case 14 /* INPLAY_OUTFIELD_OUT_TAG_FAIL */:
    case 8 /* INPLAY_INFIELD_AIR_OUT */:
    case 13 /* INPLAY_OUTFIELD_OUT_TAG_SUCCESS */:
    case 15 /* INPLAY_INFIELD_SINGLE */:
    case 16 /* INPLAY_OUTFIELD_SINGLE */:
    case 17 /* INPLAY_DOUBLE */:
    case 18 /* INPLAY_TRIPLE */:
    case 19 /* INPLAY_HOMERUN */:
    case "WO" /* WALK_OFF */:
    default:
      return team;
  }
}
function defenseStats(team, game, pitch2) {
  return fieldingStats(pitchingStats(team, game, pitch2), game, pitch2);
}

// src/stats/logStats.ts
var logStats = (state, inningChange = false) => {
  const offenseStatsTeam = inningChange ? getDefenseKey(state) : getOffenseKey(state);
  const defenseStatsTeam = inningChange ? getOffenseKey(state) : getDefenseKey(state);
  const thrown = lastPitch_default(state);
  return {
    ...state,
    [offenseStatsTeam]: offenseStats(state[offenseStatsTeam], state, thrown),
    [defenseStatsTeam]: defenseStats(state[defenseStatsTeam], state, thrown)
  };
};
var logStats_default = logStats;

// src/batters/batterUp.ts
var whoisNextBatter = (state, offense = true) => {
  const team = offense ? getOffense(state) : getDefense(state);
  const currentBatterOrder = team.lineup.indexOf(state.atBat);
  return currentBatterOrder >= 0 ? team.lineup[(currentBatterOrder + 1) % team.lineup.length] : team.lineup[0];
};
var batterUp = (state, inningChange = false) => {
  const onDeck = inningChange ? state.nextHalfAtBat : whoisNextBatter(state);
  const onDeckNextInning = inningChange ? whoisNextBatter(state, false) : state.nextHalfAtBat;
  const next = mergeDeepRight2({
    ...logStats_default(state, inningChange),
    atBat: onDeck,
    nextHalfAtBat: onDeckNextInning
  }, {
    bases: {
      [3 /* HOME */]: 0
    }
  });
  return {
    ...next,
    [getOffenseKey(state)]: offenseStats(next[getOffenseKey(state)], next, "PA" /* PLATE_APPEARANCE */),
    [getDefenseKey(state)]: defenseStats(next[getDefenseKey(state)], next, "PA" /* PLATE_APPEARANCE */)
  };
};
var batterUp_default = batterUp;

// src/inning/nextInning.ts
var nextInning = (state) => {
  const currentInning = state.inning;
  const boxScore = [...state.boxScore];
  if (currentInning.half === 1 /* BOTTOM */) {
    boxScore.push(EMPTY_BOX);
  }
  return {
    inning: {
      number: currentInning.half === 1 /* BOTTOM */ ? currentInning.number + 1 : currentInning.number,
      half: currentInning.half === 1 /* BOTTOM */ ? 0 /* TOP */ : 1 /* BOTTOM */
    },
    outs: 0,
    count: NEW_COUNT,
    bases: EMPTY_BASES,
    boxScore
  };
};
var nextInning_default = nextInning;

// src/pitches/logPitch.ts
function logPitch(state, pitch2) {
  if (!state.configuration.recordingStats)
    return state;
  return {
    ...state,
    pitches: [...state.pitches, pitch2]
  };
}
var logPitch_default = logPitch;

// src/score/score.ts
var homeScore = (state) => {
  return state.boxScore.reduce((total, inning) => total + inning.homeTeam, 0);
};
var awayScore = (state) => {
  return state.boxScore.reduce((total, inning) => total + inning.awayTeam, 0);
};
var offenseScore = (state) => {
  return state.boxScore.reduce((total, inning) => total + inning[getOffenseKey(state)], 0);
};
var defenseScore = (state) => {
  return state.boxScore.reduce((total, inning) => total + inning[getDefenseKey(state)], 0);
};

// src/gameReducer.ts
var strike = (state) => {
  return countReducer({
    ...state,
    count: {
      ...state.count,
      strikes: state.count.strikes + 1
    }
  });
};
var foulBall = (state) => {
  return countReducer({
    ...state,
    count: {
      ...state.count,
      strikes: Math.min(state.count.strikes + 1, state.configuration.maxStrikes - 1)
    }
  }).next;
};
var out = (state) => {
  return outsReducer({
    ...state,
    outs: state.outs + 1,
    count: NEW_COUNT
  });
};
function start(initial) {
  return batterUp_default({
    ...initial,
    gameStarted: true,
    atBat: initial.awayTeam.lineup[initial.awayTeam.lineup.length - 1],
    nextHalfAtBat: initial.homeTeam.lineup[0],
    [getOffenseKey(initial)]: offenseStats(getOffense(initial), initial, "START" /* START */),
    [getDefenseKey(initial)]: defenseStats(getDefense(initial), initial, "START" /* START */)
  });
}
function pitch(initial, pitch2) {
  const state = logPitch_default(initial, pitch2);
  switch (pitch2) {
    case 0 /* BALL */:
      return countReducer({
        ...state,
        count: {
          ...state.count,
          balls: state.count.balls + 1
        }
      }).next;
    case 1 /* BALL_WILD */: {
      const notAWalk = state.configuration.rules[0 /* RunnersAdvanceOnWildPitch */] && state.count.balls < state.configuration.maxBalls - 1;
      const { next, proceed } = countReducer({
        ...state,
        bases: advanceRunners_default(state.bases, notAWalk ? 1 : 0),
        count: {
          ...state.count,
          balls: state.count.balls + 1
        }
      });
      const { next: needsHomeCleared, proceed: notRunLimit } = proceed ? basesReducer(next) : { next, proceed };
      return notRunLimit ? mergeDeepRight3(needsHomeCleared, { bases: { [3 /* HOME */]: 0 } }) : needsHomeCleared;
    }
    case 2 /* STRIKE_SWINGING */:
      return strike(state).next;
    case 3 /* STRIKE_LOOKING */: {
      if (state.configuration.rules[2 /* CaughtLookingRule */]) {
        const { next, proceed } = outsReducer({
          ...state,
          outs: state.outs + 1,
          count: NEW_COUNT,
          [getDefenseKey(state)]: defenseStats(getDefense(state), state, "_K" /* STRIKEOUT_LOOKING */),
          [getOffenseKey(state)]: offenseStats(getOffense(state), state, "_K" /* STRIKEOUT_LOOKING */)
        });
        return proceed ? batterUp_default(next) : next;
      }
      return strike(state).next;
    }
    case 5 /* STRIKE_FOUL_ZONE */: {
      if (state.configuration.rules[3 /* FoulToTheZoneIsStrikeOut */] && state.count.strikes === state.configuration.maxStrikes - 1) {
        const { next, proceed } = outsReducer({
          ...state,
          outs: state.outs + 1,
          count: NEW_COUNT
        });
        return proceed ? batterUp_default(next) : next;
      }
      return foulBall(state);
    }
    case 6 /* STRIKE_FOUL_CAUGHT */:
    case 7 /* INPLAY_INFIELD_GRD_OUT */:
    case 9 /* INPLAY_INFIELD_AIR_OUT_INFIELD_FLY */:
    case 8 /* INPLAY_INFIELD_AIR_OUT */:
    case 12 /* INPLAY_OUTFIELD_OUT */: {
      const { next, proceed } = out(state);
      return proceed ? batterUp_default(next) : next;
    }
    case 14 /* INPLAY_OUTFIELD_OUT_TAG_FAIL */: {
      const { next, proceed } = outsReducer({
        ...state,
        outs: state.outs + 2,
        bases: {
          ...state.bases,
          [2 /* THIRD */]: 0
        }
      });
      return proceed ? batterUp_default(next) : next;
    }
    case 13 /* INPLAY_OUTFIELD_OUT_TAG_SUCCESS */: {
      const { next: inningContinues, proceed: checkForRuns } = out(state);
      if (!checkForRuns)
        return inningContinues;
      const { next, proceed } = state.bases[2 /* THIRD */] === 1 ? basesReducer({
        ...inningContinues,
        bases: {
          ...inningContinues.bases,
          [2 /* THIRD */]: 0,
          [3 /* HOME */]: inningContinues.bases[3 /* HOME */] + 1
        }
      }) : { next: inningContinues, proceed: false };
      return proceed ? batterUp_default(next) : next;
    }
    case 4 /* STRIKE_FOUL */:
      return foulBall(state);
    case 10 /* INPLAY_INFIELD_OUT_DP_SUCCESS */: {
      const forced = forcedRunner(state.bases);
      if (forced === void 0) {
        console.warn("double play attemped without a forced runner", state);
        return state;
      }
      const { next: inningContinues, proceed: checkForRuns } = outsReducer({
        ...state,
        outs: state.outs + 2,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, 1),
          [forced + 1]: 0
        }
      });
      const { next, proceed } = checkForRuns ? basesReducer(inningContinues) : { next: inningContinues, proceed: checkForRuns };
      return proceed ? batterUp_default(next) : next;
    }
    case 11 /* INPLAY_INFIELD_OUT_DP_FAIL */: {
      const forced = forcedRunner(state.bases);
      if (forced === void 0) {
        console.warn("double play attemped without a forced runner", state);
        return state;
      }
      const { next: inningContinues, proceed: checkForRuns } = outsReducer({
        ...state,
        outs: state.outs + 1,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, 1),
          [forced + 1]: 0,
          [0 /* FIRST */]: 1
        }
      });
      const { next, proceed } = checkForRuns ? basesReducer(inningContinues) : { next: inningContinues, proceed: checkForRuns };
      return proceed ? batterUp_default(next) : next;
    }
    case 15 /* INPLAY_INFIELD_SINGLE */: {
      const { next, proceed } = basesReducer({
        ...state,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, 1),
          [0 /* FIRST */]: 1
        }
      });
      return proceed ? batterUp_default(next) : next;
    }
    case 16 /* INPLAY_OUTFIELD_SINGLE */: {
      const extraBase = state.configuration.rules[1 /* RunnersAdvanceExtraOn2Outs */] && state.outs === state.configuration.maxOuts - 1;
      const { next, proceed } = basesReducer({
        ...state,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, extraBase ? 2 : 1),
          [0 /* FIRST */]: 1
        }
      });
      return proceed ? batterUp_default(next) : next;
    }
    case 17 /* INPLAY_DOUBLE */: {
      const extraBase = state.configuration.rules[1 /* RunnersAdvanceExtraOn2Outs */] && state.outs === state.configuration.maxOuts - 1;
      const { next, proceed } = basesReducer({
        ...state,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, extraBase ? 3 : 2),
          [1 /* SECOND */]: 1
        }
      });
      return proceed ? batterUp_default(next) : next;
    }
    case 18 /* INPLAY_TRIPLE */: {
      const { next, proceed } = basesReducer({
        ...state,
        count: NEW_COUNT,
        bases: {
          ...advanceRunners_default(state.bases, 3),
          [2 /* THIRD */]: 1
        }
      });
      return proceed ? batterUp_default(next) : next;
    }
    case 19 /* INPLAY_HOMERUN */: {
      const { next, proceed } = basesReducer({
        ...state,
        count: NEW_COUNT,
        bases: advanceRunners_default(state.bases, 4)
      });
      return proceed ? batterUp_default(next) : next;
    }
    default:
      console.warn("PITCH NOT IMPLEMENTED", pitch2);
      return state;
  }
}
function countReducer(intermediate) {
  if (intermediate.count.balls >= intermediate.configuration.maxBalls) {
    const withStats = {
      ...intermediate,
      [getOffenseKey(intermediate)]: offenseStats(getOffense(intermediate), intermediate, "W" /* WALK */),
      [getDefenseKey(intermediate)]: defenseStats(getDefense(intermediate), intermediate, "W" /* WALK */)
    };
    const { next, proceed } = basesReducer(mergeDeepRight3(withStats, {
      count: NEW_COUNT,
      bases: {
        ...advanceRunners_default(withStats.bases, 1, false),
        [0 /* FIRST */]: 1
      }
    }));
    return { next: proceed ? batterUp_default(next) : next, proceed };
  }
  if (intermediate.count.strikes >= intermediate.configuration.maxStrikes) {
    const event = lastPitch_default(intermediate) === 3 /* STRIKE_LOOKING */ ? "_K" /* STRIKEOUT_LOOKING */ : "K" /* STRIKEOUT_SWINGING */;
    const withStats = {
      ...intermediate,
      [getDefenseKey(intermediate)]: defenseStats(getDefense(intermediate), intermediate, event),
      [getOffenseKey(intermediate)]: offenseStats(getOffense(intermediate), intermediate, event)
    };
    const { next, proceed } = outsReducer(mergeDeepRight3(withStats, { outs: withStats.outs + 1, count: NEW_COUNT }));
    return { next: proceed ? batterUp_default(next) : next, proceed };
  }
  return { next: logStats_default(intermediate), proceed: true };
}
function basesReducer(intermediate) {
  if (intermediate.bases[3 /* HOME */] > 0) {
    const withStats = {
      ...intermediate,
      [getOffenseKey(intermediate)]: offenseStats(getOffense(intermediate), intermediate, "RBI" /* RBI */),
      [getDefenseKey(intermediate)]: defenseStats(getDefense(intermediate), intermediate, "RBI" /* RBI */)
    };
    const newScore = withStats.boxScore.map((inning) => ({ ...inning }));
    newScore[withStats.inning.number - 1][getOffenseKey(withStats)] += withStats.bases[3 /* HOME */];
    return runsReducer({ ...withStats, boxScore: newScore });
  }
  return { next: intermediate, proceed: true };
}
function logRunStats(intermediate) {
  return intermediate;
}
function runsReducer(intermediate) {
  const runsThisInning = intermediate.boxScore[intermediate.inning.number - 1][getOffenseKey(intermediate)];
  const runLimitSet = intermediate.configuration.maxRuns > 0;
  const practicalRunLimit = intermediate.configuration.rules[7 /* DoubleRunLimitInLastInning */] && intermediate.inning.number >= intermediate.configuration.maxInnings ? intermediate.configuration.maxRuns * 2 : intermediate.configuration.maxRuns;
  if (runLimitSet && runsThisInning >= practicalRunLimit) {
    const shouldSetRunsToMax = !intermediate.configuration.rules[5 /* AllowSinglePlayRunsToPassLimit */];
    const newScore = shouldSetRunsToMax ? practicalRunLimit : runsThisInning;
    const newBoxes = [...intermediate.boxScore];
    newBoxes[intermediate.inning.number - 1][getOffenseKey(intermediate)] = newScore;
    const withRunsAdjusted = {
      ...intermediate,
      boxScore: newBoxes
    };
    const withRunsStats = logRunStats(withRunsAdjusted);
    const needsBatterSwitch = {
      ...withRunsStats,
      ...nextInning_default(withRunsStats)
    };
    return { next: batterUp_default(needsBatterSwitch, true), proceed: false };
  }
  if (intermediate.inning.number >= intermediate.configuration.maxInnings && intermediate.inning.half === 1 /* BOTTOM */ && homeScore(intermediate) > awayScore(intermediate)) {
    const withStats = logStats_default(logRunStats(intermediate));
    return {
      next: {
        ...withStats,
        [getOffenseKey(intermediate)]: offenseStats(getOffense(intermediate), intermediate, "WO" /* WALK_OFF */),
        gameOver: true
      },
      proceed: false
    };
  }
  return { next: logRunStats(intermediate), proceed: true };
}
function outsReducer(intermediate) {
  if (intermediate.outs >= intermediate.configuration.maxOuts) {
    const withStats = {
      ...intermediate,
      [getOffenseKey(intermediate)]: offenseStats(getOffense(intermediate), intermediate, "INNING" /* INNING_END */)
    };
    const needsBatterSwitch = {
      ...withStats,
      ...nextInning_default(withStats)
    };
    const { next, proceed } = inningsReducer(needsBatterSwitch);
    return { next: proceed ? batterUp_default(next, true) : next, proceed: false };
  }
  return { next: intermediate, proceed: true };
}
var undoInningChange = (intermediate) => {
  return {
    ...intermediate,
    inning: {
      number: intermediate.inning.number - 1,
      half: 1 /* BOTTOM */
    },
    boxScore: [...intermediate.boxScore.slice(0, intermediate.boxScore.length - 1)]
  };
};
function inningsReducer(intermediate) {
  const homeTotal = homeScore(intermediate);
  const awayTotal = awayScore(intermediate);
  const homeWinning = homeTotal > awayTotal;
  const lastInning = intermediate.inning.number === intermediate.configuration.maxInnings;
  if (lastInning && intermediate.inning.half === 1 /* BOTTOM */ && homeWinning) {
    return {
      next: { ...logStats_default(intermediate), gameOver: true },
      proceed: false
    };
  }
  if (intermediate.inning.number > intermediate.configuration.maxInnings) {
    if (homeTotal !== awayTotal) {
      const next = {
        ...undoInningChange(intermediate),
        gameOver: true
      };
      return { next, proceed: false };
    } else {
      if (intermediate.configuration.allowExtras === false) {
        const next = {
          ...undoInningChange(intermediate),
          gameOver: true
        };
        return { next, proceed: false };
      } else if (intermediate.configuration.allowExtras === void 0) {
      }
    }
  }
  return { next: intermediate, proceed: true };
}

// src/edits/manualEdit.ts
import mergeDeepRight4 from "ramda/src/mergeDeepRight.js";
import omit from "ramda/src/omit.js";
var isValidBoxScore = (candidate) => {
  return (candidate == null ? void 0 : candidate.every((box) => Boolean(box && ((box == null ? void 0 : box.awayTeam) ?? -1) >= 0 && ((box == null ? void 0 : box.homeTeam) ?? -1) >= 0))) ?? false;
};
var isValidLineup = (candidate) => {
  return (candidate == null ? void 0 : candidate.every((name) => typeof name === "string")) ?? false;
};
var manualEdit = (game, edit) => {
  if (Object.keys(edit).length === 0)
    return game;
  const merged = {
    ...mergeDeepRight4(game, omit(["boxScore", "homeTeam", "awayTeam"], edit)),
    pitches: [...game.pitches, -1],
    manualEdits: [...game.manualEdits, edit]
  };
  if (isValidBoxScore(edit.boxScore)) {
    merged.boxScore = edit.boxScore;
  }
  if (edit.homeTeam) {
    merged.homeTeam = mergeDeepRight4(game.homeTeam, omit(["lineup", "startingLineup"], edit.homeTeam));
    if (isValidLineup(edit.homeTeam.lineup)) {
      merged.homeTeam.lineup = edit.homeTeam.lineup;
    }
    if (isValidLineup(edit.homeTeam.startingLineup)) {
      merged.homeTeam.startingLineup = edit.homeTeam.startingLineup;
    }
  }
  if (edit.awayTeam) {
    merged.awayTeam = mergeDeepRight4(game.awayTeam, omit(["lineup", "startingLineup"], edit.awayTeam));
    if (isValidLineup(edit.awayTeam.lineup)) {
      merged.awayTeam.lineup = edit.awayTeam.lineup;
    }
    if (isValidLineup(edit.awayTeam.startingLineup)) {
      merged.awayTeam.startingLineup = edit.awayTeam.startingLineup;
    }
  }
  return merged;
};
var manualEdit_default = manualEdit;

// src/history/hydrate.ts
var hydrateGame = (gameStub, pitchIndex) => {
  const pitchLog = [...gameStub.pitches].slice(0, pitchIndex);
  const editLog = [...gameStub.manualEdits];
  const stubbier = { ...gameStub, pitches: [], manualEdits: [] };
  let editCursor = 0;
  return pitchLog.reduce((game, thrown) => {
    if (thrown === -1) {
      const edit = editLog[editCursor];
      editCursor += 1;
      return manualEdit_default(game, edit);
    }
    return pitch(game, thrown);
  }, stubbier);
};
var hydrate_default = hydrateGame;

// src/io.ts
import pickBy from "ramda/src/pickBy.js";
import mergeDeepRight5 from "ramda/src/mergeDeepRight.js";
import pick from "ramda/src/pick.js";
var stripTeam = (team) => {
  const rosterEssentials = Object.keys(team.roster).reduce((acc, id) => {
    acc[id] = pick(["id", "name"], team.roster[id]);
    return acc;
  }, {});
  return {
    startingLineup: team.startingLineup,
    startingDefense: team.startingDefense,
    roster: rosterEssentials,
    id: team.id,
    name: team.name
  };
};
var serializeGame = (game) => {
  const standardConfig = defaultConfiguration();
  const standardRules = defaultRules();
  const essentials = pick([
    "configuration",
    "pitches",
    "manualEdits"
  ], game);
  const configEssentials = pickBy((value, key) => standardConfig[key] !== value, essentials.configuration);
  const rulesEssentials = pickBy((value, key) => standardRules[key] !== value, essentials.configuration.rules);
  return btoa(JSON.stringify({
    ...essentials,
    configuration: {
      ...configEssentials,
      rules: rulesEssentials
    },
    awayTeam: stripTeam(game.awayTeam),
    homeTeam: stripTeam(game.homeTeam)
  }));
};
var parseRoster = (team) => (id) => ({
  id,
  name: team.roster[id].name,
  position: team.startingDefense[id]
});
var deserializeGame = (serialized) => {
  let parsed;
  try {
    parsed = JSON.parse(atob(serialized));
  } catch (e) {
    console.error("error parsing serialized game", e);
    return defaultGame();
  }
  if (!parsed)
    return defaultGame();
  const awayTeam = newTeam((parsed == null ? void 0 : parsed.awayTeam.startingLineup.map(parseRoster(parsed.awayTeam))) ?? [], parsed == null ? void 0 : parsed.awayTeam.id, parsed == null ? void 0 : parsed.awayTeam.name);
  const homeTeam = newTeam((parsed == null ? void 0 : parsed.homeTeam.startingLineup.map(parseRoster(parsed.homeTeam))) ?? [], parsed == null ? void 0 : parsed.homeTeam.id, parsed == null ? void 0 : parsed.homeTeam.name);
  const initialGame = defaultGame(awayTeam, homeTeam);
  const toBeMerged = pick(["configuration"], parsed);
  const merged = mergeDeepRight5(start(initialGame), toBeMerged);
  return {
    ...merged,
    pitches: (parsed == null ? void 0 : parsed.pitches) ?? [],
    manualEdits: (parsed == null ? void 0 : parsed.manualEdits) ?? []
  };
};
var serializeTeam = (team) => {
  return btoa(JSON.stringify(stripTeam(team)));
};
var deserializeTeam = (serialized) => {
  let parsed;
  try {
    parsed = JSON.parse(atob(serialized));
  } catch (e) {
    console.error("error parsing serialized team", e);
    return defaultTeam();
  }
  if (!parsed)
    return defaultTeam();
  return newTeam((parsed == null ? void 0 : parsed.startingLineup.map(parseRoster(parsed))) ?? [], parsed == null ? void 0 : parsed.id, parsed == null ? void 0 : parsed.name) ?? defaultTeam();
};

// src/edits/commonEdits.ts
var pitcherSwap = (game, newPitcher) => {
  const pitcher = getPitcher(getDefense(game));
  if (!pitcher)
    return game;
  return fielderSwap(game, newPitcher, pitcher);
};
var fielderSwap = (game, newFielder, oldFielder) => {
  return fielderRotate(game, newFielder, oldFielder);
};
var fielderRotate = (game, ...fielders) => {
  const defenseKey = getDefenseKey(game);
  const defenseTeam = getDefense(game);
  if (fielders.some((fielder) => !Object.keys(defenseTeam.defense).includes(fielder))) {
    console.warn("trying to swap fielders not on team: ", ...fielders, defenseKey);
    return game;
  }
  const newDefense = {};
  fielders.forEach((fielder, i) => {
    const nextIndex = i + 1 === fielders.length ? 0 : i + 1;
    const nextPosition = defenseTeam.defense[fielders[nextIndex]];
    newDefense[fielder] = nextPosition;
  });
  return manualEdit_default(game, {
    [defenseKey]: {
      defense: newDefense
    }
  });
};

// src/pitches/possiblePitches.ts
var getPossiblePitches = (game) => {
  const values = Object.values(Pitches);
  const allPitches = values.filter((value) => typeof value === "string").map((value) => value);
  return allPitches.filter((p) => {
    const pitch2 = Pitches[p];
    switch (pitch2) {
      case 5 /* STRIKE_FOUL_ZONE */:
        return game.count.strikes === game.configuration.maxStrikes - 1 && game.configuration.rules[3 /* FoulToTheZoneIsStrikeOut */];
      case 9 /* INPLAY_INFIELD_AIR_OUT_INFIELD_FLY */:
        return game.bases[0 /* FIRST */] > 0;
      case 10 /* INPLAY_INFIELD_OUT_DP_SUCCESS */:
      case 11 /* INPLAY_INFIELD_OUT_DP_FAIL */:
        return forcedRunner(game.bases) !== void 0 && game.outs < game.configuration.maxOuts - 1;
      case 13 /* INPLAY_OUTFIELD_OUT_TAG_SUCCESS */:
      case 14 /* INPLAY_OUTFIELD_OUT_TAG_FAIL */:
        return game.bases[2 /* THIRD */] > 0 && game.outs < game.configuration.maxOuts - 1 && game.configuration.rules[4 /* ThirdBaseCanTag */];
      default:
        return true;
    }
  }).map((k) => {
    const pitch2 = Pitches[k];
    return pitch2;
  });
};
var possiblePitches_default = getPossiblePitches;

// src/stats/statAggregations.ts
var battingAverage = ({ offenseStats: offenseStats2 }) => {
  const { hits: hits2, atbats } = offenseStats2;
  if (!atbats)
    return 0;
  return hits2 / atbats;
};
var extraBaseHit = ({ offenseStats: offenseStats2 }) => {
  const { doubles, triples, homeruns } = offenseStats2;
  return doubles + triples + homeruns;
};
var groundOutToAirOutRatio = ({ offenseStats: offenseStats2 }) => {
  const { flyOuts, groundOuts } = offenseStats2;
  if (!flyOuts)
    return 0;
  return groundOuts / flyOuts;
};
var onBasePercentage = ({ offenseStats: offenseStats2 }) => {
  const { plateAppearance, hits: hits2, walks, doublePlayFails } = offenseStats2;
  if (!plateAppearance)
    return 0;
  return (hits2 + walks + doublePlayFails) / plateAppearance;
};
var onBasePlusSlugging = (player) => {
  return onBasePercentage(player) + sluggingPercentage(player);
};
var sluggingPercentage = (player) => {
  const { atbats } = player.offenseStats;
  if (!atbats)
    return 0;
  return totalBases(player) / atbats;
};
var totalBases = ({ offenseStats: offenseStats2 }) => {
  const { singles, doubles, triples, homeruns } = offenseStats2;
  return singles + 2 * doubles + 3 * triples + 4 * homeruns;
};
var pitchCount = ({ defenseStats: defenseStats2 }) => {
  const { pitching } = defenseStats2;
  const { balls, strikes, groundOuts, flyOuts, singles, doubles, triples, homeruns } = pitching;
  return balls + strikes + groundOuts + flyOuts + singles + doubles + triples + homeruns;
};
var walksAndHitsPerInningPitched = (player) => {
  const innings = inningsPitched(player);
  if (!innings)
    return 0;
  const { walks } = player.defenseStats.pitching;
  return (walks + hits(player)) / innings;
};
var outs = ({ defenseStats: defenseStats2 }) => {
  const { strikeoutsLooking, strikeoutsSwinging, groundOuts, flyOuts } = defenseStats2.pitching;
  return strikeoutsLooking + strikeoutsSwinging + groundOuts + flyOuts;
};
var hits = ({ defenseStats: defenseStats2 }) => {
  const { singles, doubles, triples, homeruns } = defenseStats2.pitching;
  return singles + doubles + triples + homeruns;
};
var inningsPitched = (player) => {
  return outs(player) / 3;
};

// src/stats/teamAggregations.ts
var teamEarnedHits = ({ roster }) => {
  return Object.values(roster).reduce((acc, { offenseStats: offenseStats2 }) => {
    acc += offenseStats2.hits;
    return acc;
  }, 0);
};
var teamLOB = ({ roster }) => {
  return Object.values(roster).reduce((acc, { offenseStats: offenseStats2 }) => {
    acc += offenseStats2.LOB;
    return acc;
  }, 0);
};
export {
  Bases,
  EMPTY_BASES,
  EMPTY_BOX,
  GameEvent,
  InningHalf,
  OptionalRules,
  Pitches,
  Position,
  awayScore,
  battingAverage,
  defaultConfiguration,
  defaultGame,
  defaultPlayer,
  defaultRules,
  defaultTeam,
  defenseScore,
  deserializeGame,
  deserializeTeam,
  extraBaseHit,
  fielderRotate,
  fielderSwap,
  getBench,
  getDefense,
  getInfield,
  getOffense,
  getOutfield,
  getPitcher,
  possiblePitches_default as getPossiblePitches,
  groundOutToAirOutRatio,
  pitch as handlePitch,
  hits,
  homeScore,
  hydrate_default as hydrateGame,
  inningsPitched,
  lastPitch_default as lastPitch,
  manualEdit_default as manualEdit,
  newTeam,
  offenseScore,
  onBasePercentage,
  onBasePlusSlugging,
  outs,
  pitchCount,
  pitcherSwap,
  serializeGame,
  serializeTeam,
  sluggingPercentage,
  start,
  teamEarnedHits,
  teamLOB,
  totalBases,
  walksAndHitsPerInningPitched
};
